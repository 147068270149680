import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import { drawerWidth } from "./sidebar/Sidebar";

export default function Layout() {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="center"
      minWidth="min-content"
    >
      <Sidebar />
      <Box
        component="main"
        flexGrow={1}
        maxWidth={{ sm: `calc(100% - ${drawerWidth}px)` }}
        p={5}
      >
        <Outlet />
      </Box>
    </Stack>
  );
}
