import { toast } from "react-toastify";
import axiosInstance from "./axios";

export const login = async (body) => {
  try {
    const response = await axiosInstance.post("/auth", body);
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Invalid username or password");
  }
  return null;
};

export const getCaptainDetails = async (body) => {
  try {
    const response = await axiosInstance.get(`/captains?phoneNumber=${body.phone}&limit=${body.limit}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 400 || error.response.status === 500) {
      toast.error("The provided phone number does not belong to any captain");
    }
  }
  return null;
};

export const postCollection = async (body) => {
  try {
    console.log(body);
    const response = await axiosInstance.post(`/captains/${body.captainId}/collect`, { amount: body.amount });
    return response.data;
  } catch (error) { }
  return null;
};
