import { LoadingButton } from "@mui/lab";
import { Input, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import ContainerCard from "../components/ContainerCard";
import { getCaptainDetails, postCollection } from "../utilities/api";

const captainTransactionsCols = [
  {
    headerName: "#",
    field: "id",
    sortable: false,
    maxWidth: 50,
    flex: 1
  }, {
    headerName: "Time",
    field: "time",
    sortable: true,
    minWidth: 150,
    flex: 1
  },
  {
    headerName: "Transaction",
    field: "type",
    sortable: false,
    flex: 1
  },
  {
    headerName: "Amount",
    field: "amount",
    sortable: false,
    flex: 1
  },
];

export default function PromotionalMessageMUI() {
  const [loading, setLoading] = useState(false);
  const [captain, setCaptainData] = useState({});
  const [captainPayments, setPayments] = useState([]);

  const refreshCaptainDetails = async (phone) => {
    setLoading(true);
    setPayments([]);
    setCaptainData({});
    const response = await getCaptainDetails(phone);
    if (response) {
      setCaptainData({
        id: response.id,
        name: response.name,
        balance: response.balance,
        phone: response.phoneNumber,
        canCharge: true
      });
      if (response.payments) {

        for (let i = 0; i < response.payments.length; i++) {
          response.payments[i].id = i + 1;
        }
        setPayments(response.payments)
      }
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      phone: "",
      captain: {},
      payments: [],
      charge: ""
    },
    onSubmit: refreshCaptainDetails,
  });

  const handleCharge = async () => {
    let bodyData = { captainId: captain.id, amount: formik.values.charge };
    setLoading(true);
    setPayments([]);
    setCaptainData({});
    formik.values.charge = "";
    let response = await postCollection(bodyData)
    if (response) {
      setCaptainData({
        id: response.id,
        name: response.name,
        balance: response.balance,
        phone: response.phoneNumber,
        canCharge: false
      });
      if (response.payments) {
        for (let i = 0; i < response.payments.length; i++) {
          response.payments[i].id = i + 1;
        }
        setPayments(response.payments)
      }
      toast.success(`Added ${bodyData.amount} SDG successfully`);
    }
    
    formik.values.charge = "";
    setLoading(false);
  };

  return (
    <Stack gap={3}>
      <ContainerCard>
        <Typography variant="h4" fontWeight={700} mb={2}>
          Cash Payment
        </Typography>
        <Stack flexDirection="row" gap={20}>
          <Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
            <Stack flexDirection={{ xs: "column", md: "row" }} gap={3}>
              <Input
                id="phone"
                name="phone"
                variant="outlined"
                placeholder="Enter captain phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />

              {captain.name && (
                <Stack flexDirection="column" gap={2}>
                  <Typography> {`Name: ${captain.name}`}</Typography>
                  <Typography> {`Balance: ${captain.balance}`}</Typography>
                </Stack>
              )}

            </Stack>

            <Stack flexDirection="row" gap={2}>
              <LoadingButton variant="outlined" type="submit" loading={loading}>
                Get details
              </LoadingButton>
            </Stack>

          </Stack>
          {captain.canCharge && (
            <Stack flexDirection="row" gap={10} maxHeight="50px">
              <Input
                id="charge"
                name="charge"
                variant="outlined"
                placeholder="Enter charge amount (SDG)"
                value={formik.values.charge}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.charge && Boolean(formik.errors.charge)}
                helperText={formik.touched.charge && formik.errors.charge}
              />
              <LoadingButton variant="contained" type="button" loading={loading} onClick={handleCharge} minWidth="550px">
                Charge
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </ContainerCard>

      {captainPayments.length > 1 && (
        <ContainerCard>
          <Typography variant="h5" fontWeight={500} mb={2}>
            Previous transactions
          </Typography>
          <DataGrid
            rows={captainPayments}
            columns={captainTransactionsCols}
            autoHeight

            disableColumnFilter
            disableColumnMenu
          />
        </ContainerCard>
      )}
    </Stack>
  );
}
