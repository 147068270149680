// import Bonus from "../pages/Bonus";
import LoginMUI from "../pages/LoginMUI";
import PromotionalMessageMUI from "../pages/PromotionalMessageMUI";

export const route = [
  {
    id: "sms",
    path: "/",
    component: <PromotionalMessageMUI />,
  }
];
export const fullPage = [
  {
    id: "login",
    path: "/login",
    component: <LoginMUI />,
  },
];
