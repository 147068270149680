import { ToastContainer as ToastifyContainer, Zoom } from "react-toastify";

export default function ToastContainer() {
  return (
    <ToastifyContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      transition={Zoom}
    />
  );
}
