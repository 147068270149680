import { createContext, useState } from "react";
import useAxiosInterceptors from "../hooks/useAxiosInterceptors";

const AuthContext = createContext({
  token: "",
  login: (token) => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const tokenData = localStorage.getItem("TOKEN");
  let initialToken;
  if (tokenData) {
    initialToken = tokenData;
  }

  const [token, setToken] = useState(initialToken);

  const loginHandler = (token) => {
    setToken(token);
    localStorage.setItem("TOKEN", token);
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("TOKEN");
  };

  useAxiosInterceptors(token, logoutHandler);

  const contextValue = {
    token: token,
    login: loginHandler,
    logout: logoutHandler,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
