import { useEffect } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../utilities/axios";

const useAxiosInterceptors = (token, logoutHandler) => {
  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"] && token) {
          config.headers["Authorization"] = `${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,

      (error) => {
        if (error?.response?.status === 403) {
          logoutHandler();
        }

        if (error?.response && error.response?.data?.Error) {
          toast.error(error.response?.data?.Error);
        } else {
          toast.error(
            error.response?.data?.message ||
              error?.message ||
              "An error occurred"
          );
        }

        return Promise.reject(error);
      }
    );
    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [token]);
};

export default useAxiosInterceptors;
