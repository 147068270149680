import * as MdOutline from "react-icons/md";
// import { BiMoney } from "react-icons/bi";

export const menuItems = [
  {
    id: "cash",
    name: "Cash collection",
    icon: <MdOutline.MdOutlineTextsms />,
    path: "/",
  },
  // {
  //   id: "bonus",
  //   name: "Bonus",
  //   icon: <BiMoney />,
  //   path: "/bonus",
  // },
];
